.Toastify__toast-icon {
  svg {
    fill: white !important;
  }
}

.Toastify__toast-body {
  color: white !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22px;
  padding: 0.4rem 0;
}

.Toastify__toast--error {
  border-radius: 8px !important;
  background-image: linear-gradient(
    to right,
    #fe323c,
    #f91352,
    #ef0066,
    #e00079,
    #cc008a
  ) !important;
}

.Toastify__toast--success {
  border: 1px solid #3a9ea5 !important;
  border-radius: 8px !important;
  background-image: linear-gradient(
    to right,
    #25d2a8,
    #1ad1a0,
    #0fd097,
    #08cf8e,
    #08ce84
  ) !important;
}

.Toastify__toast--info {
  border: 1px solid #3a9ea5 !important;
  border-radius: 8px !important;
  background-image: linear-gradient(
    to right,
    #46b5d1,
    #3cb2d0,
    #2fafce,
    #1fabcd,
    #00a8cc
  ) !important;
}

.Toastify__toast--warning {
  border-radius: 8px !important;
  background-image: linear-gradient(
    to right,
    #fccc00,
    #febf00,
    #ffb300,
    #ffa603,
    #ff990d
  ) !important;
}
