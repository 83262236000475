@import "./toastify.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Roboto:wght@400;500;700&display=swap");

.rdt_TableBody {
  max-height: 650px !important;
  &::-webkit-scrollbar {
    width: 0.4em !important;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0) !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0) !important;
    background-color: whitesmoke !important;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    background-color: #aaa !important;
    outline: 1px solid slategrey !important;
  }
}

.ql-container {
  font-family: Inter, Helvetica, Arial, sans-serif !important;
  border: none !important;
}

.deal-form-editor {
  border: 1px solid rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.deal-form-editor .ql-editor {
  min-height: 300px;
}
